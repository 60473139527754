import React, { useState, useEffect } from "react";
import _ from "lodash";
import localforage from "localforage";
import { useIntercom } from 'react-use-intercom';
import { Game } from "./game";
import { _createBet, _deleteBet, _getDraftBetsCount, _getBetSyncNotification, _getSubscriptionPlans, _checkBetGrid, _updateUserSetting, _updateUser, _getNotification } from "../../../services";
import loaderGIF from "../../../img/newLoader.gif";
import { useStore } from "../../../store"
import { shallow } from "zustand/shallow"
import { PoppingText } from "../helper"
import { ON_BOARDING } from "../../../constants";

export default function Dashboard({ ...props }) {

  const { betCount, userData, subscriptionPlans, userPlan, token, showOnboardingNotifications,
    setBetCount, setSubscriptionPlans, setUserPlan, setBetAnalysis, setShowOnboardingNotifications } = useStore((store) => ({
      betCount: store?.betCount,
      userData: store?.userData,
      subscriptionPlans: store?.subscriptionPlans,
      userPlan: store?.userPlan,
      token: store?.token,
      showOnboardingNotifications: store?.showOnboardingNotifications,
      setBetCount: store?.setBetCount,
      setSubscriptionPlans: store?.setSubscriptionPlans,
      setUserPlan: store?.setUserPlan,
      setBetAnalysis: store?.setBetAnalysis,
      setShowOnboardingNotifications: store?.setShowOnboardingNotifications
    }), shallow)

  const { update } = useIntercom();

  const [games, setGames] = useState([]); //all the games for the screen  
  const [sportBooks, setSportBooks] = useState([]); //all the sports book return from api
  const [currentLeague, setCurrentLeague] = useState({}); //current league selected
  const [loader, setLoader] = useState(false)
  // for intercom
  function signature(query_string) {
    return crypto.createHmac('sha256', "7ecWAov1nlFkgzM6fzZUS46KpaI3XlqLfEpjNNyw").update(query_string).digest('hex');
  }
  const text = "BettorTakes will transform your betting, stop backroll leaks & repeating mistakes! \n \n Let's enter one bet together! \n What is your best bet today?"

  const plans = async () => {
    if (!ON_BOARDING?.includes(showOnboardingNotifications)) {
      let { subscription } = userData;
      if (!subscriptionPlans?.length) {
        const allPlans = await _getSubscriptionPlans();
        let plan = allPlans.data.find(cplan => cplan.plan === subscription);
        setSubscriptionPlans(allPlans?.data)
        setUserPlan(plan ?? {})
      }
      else {
        // if user plan update
        let plan = subscriptionPlans?.find(cplan => cplan.plan === subscription);
        setUserPlan(plan ?? {})

      }
    }
  }

  console.log({ showOnboardingNotifications });

  useEffect(async () => {
    // get draft bets
    let email = userData?.userId;
    window.setShowOnboardingNotifications = setShowOnboardingNotifications;
    if (!localStorage.getItem("onBoardingComplete") && (showOnboardingNotifications == ON_BOARDING[4] || showOnboardingNotifications == ON_BOARDING[5])) {
      setShowOnboardingNotifications(ON_BOARDING[3])
    }
    await checkNotification(email, userData)
    if (email != null) {
      const draftBetCounts = await _getDraftBetsCount(email, null, false);
      if (draftBetCounts.success && draftBetCounts.data > 0) {
        setBetCount(draftBetCounts.data);
      }
      else
        setBetCount(0)
    }

    if (props.games?.length)
      setGames(props.games);

    if (props.sportBooks)
      setSportBooks(props.sportBooks);


    localforage.getItem('email').then(user => {
      if (user != null) {
        const user_hash = { "user_hash": signature({ email: user }) }
        update({ user_hash, email: user });
      }
    }).catch(err => { })

    setCurrentLeague(props.currentLeague);
    setLoader(true);
    setPlayer();
    plans();
  }, []);

  const checkNotification = async (email) => {
    //   // get betsync message
    // if ((new Date() > localStorage.getItem("betSyncMsg") && (!userData?.betCount || userData?.betSyncId)) || (localStorage.getItem("onBoardingComplete") && !userData)) {
    //   const notification = await _getBetSyncNotification();
    //   if (notification.success) {
    //     let date = new Date();
    //     localStorage.setItem("betSyncMsg", date.setDate(date.getDate() + 1000))
    //     localStorage.setItem("notification", JSON.stringify(notification?.data))
    //     props.history.push("/notification");
    //   }

    // }
    // else 
    // if (email) {
    //   const notification = await _getNotification(email.toLowerCase())
    //   if (notification.success) {
    //     // maybe later handle locally
    //     if (localStorage.getItem(notification?.data?._id)) {
    //       if (new Date() < localStorage.getItem(notification?.data?._id))
    //         return;
    //     }
    //     localStorage.setItem("notification", JSON.stringify(notification?.data))
    //     props.history.push("/notification");
    //   }
    // }

  }

  const setPlayer = () => {
    const oneSignalPlayerId = new URLSearchParams(window.location.search).get("player_id");
    if (userData) {
      if (oneSignalPlayerId != localStorage.getItem("playerID")) {
        _updateUser(userData.userId, { oneSignalPlayerId })
        localStorage.setItem("playerID", oneSignalPlayerId)
      }
    }
  }

  const createBet = async (betoptionsId, sportBook, increment = true, game, confidence) => {
    let user = props.user
    // when user not login
    if (!user) {
      if (localStorage.getItem("userRandomId"))
        user = localStorage.getItem("userRandomId")
      else {
        user = Math.floor(Math.random() * 10000000000)+"@bettortakes.com"
        localStorage.setItem("userRandomId", user)
        localStorage.setItem("betCreatedWithoutUser", true)
      }
    }
    let betStatus = showOnboardingNotifications == ON_BOARDING[5] ? "B" : null
    const betCreated = await _createBet(user, game.gameId, betoptionsId, sportBook?.betLine || "", sportBook?.moneyLine, sportBook?.sbookId, confidence, null, sportBook?.sbookLink,betStatus)
    if (!_.isEmpty(betCreated) && showOnboardingNotifications == ON_BOARDING[5]) {
      setShowOnboardingNotifications(ON_BOARDING[6])
      setBetAnalysis([{...betCreated}])
      props.history.push("/review_on_board")
    }

    if (!_.isEmpty(betCreated))
      if (increment)
        setBetCount(betCount + 1)
    return betCreated
  }
  const deleteCurrentBet = async (betoId, decrement = true) => {
    if (decrement)
      setBetCount(betCount > 0 ? betCount - 1 : 0)
    return await _deleteBet(betoId);
  }


  const analyzeBet = async (e) => {
    // call the API to check the bets are right
    let user = props.user;
    let randomUser=false;
    // when user not login
    if (!user) {
      user = localStorage.getItem("userRandomId");
      randomUser=true;
    }
    await localforage.setItem("sportBooks", props.sportBooks);
    if (!randomUser) {
      let analyzeBet = await _checkBetGrid(user, token);
      if (analyzeBet.length == 0) {
        window.scrollTo(0, 0);
        setBetCount(0)
      }
      else {
        setBetAnalysis(analyzeBet)
        props.history.push("/reviewBet")

      }
    }
    else {
      localStorage.setItem("callOnStep2", true)
      if (localStorage.getItem("isJoined"))
        props.history.push("/sign-in")
      else
        props.history.push("/sign-up")
    }

  }

  const setBetsCount = (value) => {
    if (value == 1)
      setBetCount(betCount + 1)
    else
      setBetCount(betCount > 0 ? betCount - 1 : 0)
  }


  return (
    <>
      <div style={{ height: "60px" }}></div>
      {showOnboardingNotifications == ON_BOARDING[0] && <div className="onBoarding">
        <div className="container mt-15">

          <span className="df text-center fs-23">
            <PoppingText text={text} />
          </span>
          <div className="addBet df dfr p-0" key={"letsStart"} style={{ height: "52px", position: "fixed", zIndex: 1, width: "92%", bottom: "12%" }} >
            <button className="applyFilter" onClick={() => setShowOnboardingNotifications(ON_BOARDING[1])} style={{ backgroundColor: "white", color: "black" }}>
              Let's start
            </button>
          </div>
        </div>
      </div>}
      {showOnboardingNotifications == ON_BOARDING[2] && <div className="" style={{ zIndex: 111, position: "fixed", color: "white" }}>
        <div className="container" style={{ marginTop: "10px" }}>
          <span className="text-center fs-20">
            <PoppingText text={"Find you game in the list and select your best bet today. Use the arrows to toggle between bets"} />
          </span>
        </div>
      </div>}
      {loader ?
        !ON_BOARDING?.slice(0, 2)?.includes(showOnboardingNotifications) && <div>
          <div className="container p-0 dashboard" style={{ marginTop: "50px", marginBottom: "10rem" }}>
            {games.length > 0 ? (
              games.map((game, index) => game.oddsAvailable && <Game game={game} user={props.user} userPlan={userPlan} sportBooks={sportBooks} currentLeague={currentLeague} setBetCount={setBetsCount} key={index}
                showOnboardingNotifications={showOnboardingNotifications} setShowOnboardingNotifications={setShowOnboardingNotifications}
                deleteCurrentBet={deleteCurrentBet} history={props.history} createBet={createBet} />)) : (
              <div className="text fs-20 text-center" style={{ marginTop: "50px" }}>
                {currentLeague.active ? `There are no ${currentLeague.league} games scheduled for today.` : `The ${currentLeague.league} season has ended.`}
              </div>
            )}
          </div>
          {/* next screen button */}
          <div className="container" key={betCount}>

            {games.length > 0 && localStorage.getItem("onBoardingComplete") &&
              <div className="addBet df dfr p-0" key={betCount} style={{ height: "52px", position: "fixed", zIndex: 1, width: "92%", bottom: betCount > 0 ? "10%" : "12%", background: "#757575" }} >
                {betCount > 0 ?
                  <button className="applyFilter" onClick={(e) => analyzeBet(e)}>
                    ANALYZE <span className="highLigtedNumber">{betCount}</span> {betCount > 1 ? "BETS" : "BET"}
                  </button>
                  :
                  <button className="applyFilter" >
                    PICK YOUR BETS...
                  </button>
                }
              </div>
            }
          </div>

        </div> :
        <div style={{ textAlign: "center", marginTop: "25%" }}>
          <img
            src={loaderGIF}
            style={{ margin: "10px auto", display: "block" }}
          />
        </div>
      }
    </>
  );
}

