import React, { useEffect, useContext, useState } from "react";
import filterImg from "../../../img/option.png";
import close from "../../../img/close.png";
import { HeaderTabs } from "./headerTabs";
import { makeStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import Sidebar from "../../nav/sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { _getLeaguesConference } from "../../../services";
import localForage from "localforage";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    padding: "10px 20px 10px 20px",
    width: "100%",
    background: "white",
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  // for menu
  const [sideBar, showSitebar] = useState(false);
  const [filterBar, showFilterbar] = useState(false);
  const [gmSeg, setgmSeg] = useState([]);
  const [leagues, setLeage] = useState([]);
  const [conference, setConference] = useState([]);
  const [season, setSeason] = useState([]);
  const [showSideMenu, setShowSideMenu] = useState(false)

  const handleSidebar = (e) => {
    e.preventDefault();
    showSitebar(!sideBar);
    setShowSideMenu(!showSideMenu);
  };
  const handleFilterbar = (e) => {
    e.preventDefault();
    showFilterbar(!filterBar);
  };

  useEffect(async () => {
    let leagues = JSON.parse(localStorage.getItem("headerTabs"));
    if (props?.filter?.league && props?.filter?.league != "") {
      let league = props.currentLeague||localStorage.getItem("lastLeague");
      const conAndSea = await _getLeaguesConference(league)
      localStorage.setItem("statsLeague", props.filter.league);
      setgmSeg(conAndSea[0]?.gmSegs || [])
      setConference(conAndSea[0]?.conferences || [])
      setSeason(conAndSea[0]?.seasons || [])
    }
    setLeage(leagues);

  }, [props.filter]);

  const getDate = (date) => {
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1; //months from 1-12
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return year + "-" + month + "-" + day;
  };

  const fromDate = (date) => {
    const value = getDate(date);
    props.filterChange({ target: { name: "fromDate", value } });
  };

  const thruDate = (date) => {
    const value = getDate(date);
    props.filterChange({ target: { name: "thruDate", value } });
  };

  const gameSegAbb = [
    ["FG", "FULL GAME"],
    ["1H", "1ST HALF"],
    ["2H", "2ND HALF"],
    ["1Q", "1ST QTR"],
    ["2Q", "2ND QTR"],
    ["3Q", "3RD QTR"],
    ["4Q", "4TH QTR"],
    ["1P", "1ST PD"],
    ["2P", "2ND PD"],
    ["3P", "3RD PD"],
    ["FF", "FIRST 5"],
  ];

  const applyFilter = (e) => {
    props.applyFilter();
    handleFilterbar(e);
  };

  const changeGameSeg = async (event) => {
    event.preventDefault();
    props.filterChange(event);
    if (event.target.value) {
      if (props.navTabNumber === 0) {
        const conAndSea = await _getLeaguesConference(event.target.value)
        localStorage.setItem("statsLeague", event.target.value);
        setgmSeg(conAndSea[0]?.gmSegs || [])
        setConference(conAndSea[0]?.conferences || [])
        setSeason(conAndSea[0]?.seasons || [])
      }
      else {
        const selectLeage = leagues.filter(
          (lea) => lea.league == event.target.value
        )[0];
        setgmSeg(selectLeage.gmSegs);
      }
    } else setgmSeg([]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 230,
      },
    },
  };

  return (
    <>
      <header className="header">
        <div className="navContainer">
          {/* menu open button */}
          
           <button
                onClick={handleSidebar}
                className={`navToggle ${sideBar ? "open" : null}`}
              >
                <span />
                <span />
                <span />
              </button>
              

          {/* menu */}

          <span className="navH1" style={{ margin: "0 auto" }}>
            {props.header}
          </span>

          <span className="navH1">
            <img
              src={filterImg}
              width="32px"
              onClick={handleFilterbar}
              style={{ order: "2" }}
            />
          </span>

          <Sidebar sideBar={sideBar} handleSidebar={handleSidebar} history={props.history}
          setSitebar={showSitebar}
          showSideMenu={showSideMenu}
          setShowSideMenu={setShowSideMenu} />

          {/* Main nav */}
          <nav>
            <ul
              className="filterNav"
              style={filterBar ? { transform: "translateX(0)" } : null}
            >
              <div
                className=" navH1"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  {props.header == "MY ACTION"
                    ? "FILTER GAMES"
                    : "FILTER YOUR STATS"}
                </span>
                <img
                  src={close}
                  style={{ width: "32px", height: "22px", marginTop: "3px" }}
                  onClick={handleFilterbar}
                />
              </div>
              {
              props.navTabNumber!=0 && 
              ('league' in props.filter) &&
                <NativeSelect
                  style={{ marginBottom: "15px" }}
                  value={props.filter.league}
                  onChange={(e) => changeGameSeg(e)}
                  name="league"
                  className={classes.selectEmpty}
                  inputProps={{ "aria-label": "ALl Leagues" }}
                >
                  <option value="">ALL LEAGUES</option>
                  {leagues.map(
                    (lea) =>
                      !lea.hidden && (
                        <option key={lea.league} value={lea.league}>
                          {lea.league}
                        </option>
                      )
                  )}
                </NativeSelect>
              }
              {props.header == "MY STATS" && (
                <>
                  {'fromDate' in props.filter && <DatePicker
                    style={{ width: "100%" }}
                    selected={
                      props.filter.fromDate
                        ? new Date(props.filter.fromDate)
                        : ""
                    }
                    maxDate={props.filter.thruDate ? new Date(props.filter.thruDate) : new Date()}
                    placeholderText="FROM DATE"
                    className="calenderFilter"
                    onChange={(e) => {
                      fromDate(e);
                    }}
                  />
                  }
                  {'thruDate' in props.filter &&
                    <DatePicker
                      selected={
                        props.filter.thruDate
                          ? new Date(props.filter.thruDate)
                          : ""
                      }
                      maxDate={new Date()}
                      placeholderText="THRU DATE"
                      className="calenderFilter"
                      onChange={(e) => {
                        thruDate(e);
                      }}
                    />}
                  {'conference' in props.filter && props.filter.league &&
                    <NativeSelect
                      style={{ marginBottom: "15px", fontWeight: 100 }}
                      value={props.filter.conference}
                      onChange={props.filterChange}
                      name="conference"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "ALl Leagues" }}
                    >
                      <option value="" >- CONFERENCE -</option>
                      {conference.map((name) =>
                        <option key={name} value={name}>{name}</option>
                      )}
                    </NativeSelect>
                  }
                  {'season' in props.filter && props.filter.league &&
                    <NativeSelect
                      style={{ marginBottom: "15px" }}
                      value={props.filter.season}
                      onChange={props.filterChange}
                      name="season"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "ALl Leagues" }}
                    >
                      <option value="" style={{ fontWeight: "100px" }}>ALL SEASONS</option>
                      {season.map((name) =>
                        <option key={name} value={name}>{name}</option>
                      )}

                    </NativeSelect>
                  }


                  {'bet' in props.filter &&
                    <NativeSelect
                      style={{ marginBottom: "15px" }}
                      value={props.filter.bet}
                      onChange={props.filterChange}
                      name="bet"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "ALl Leagues" }}
                    >
                      <option value="">ALL BET TYPES</option>
                      <option value="POINTSPREAD">POINTSPREAD</option>
                      <option value="MONEYLINE">MONEYLINE</option>
                      <option value="TOTAL">TOTAL</option>
                    </NativeSelect>
                  }
                  {props.navTabNumber!=0 && 'gmSeg' in props.filter && gmSeg.length > 0 && props.filter.league && (

                    <NativeSelect
                      style={{ marginBottom: "15px" }}
                      value={props.filter.gmSeg}
                      onChange={props.filterChange}
                      name="gmSeg"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "ALl Leagues" }}
                    >
                      <option value="">ALL GAME SEGM.</option>
                      {gmSeg.map((name) =>
                        gameSegAbb.map(
                          ([key, value]) =>
                            name == key && (
                              <option key={name} value={value}>{value}</option>
                            )
                        )
                      )}

                    </NativeSelect>

                    // <Select
                    //   labelId="demo-mutiple-checkbox-label"
                    //   id="demo-mutiple-checkbox"
                    //   multiple
                    //   name="gameSeg"
                    //   style={{ marginBottom: "15px" }}
                    //   value={props.gameSegm}
                    //   onChange={props.filterChange}
                    //   input={<Input />}
                    //   className={classes.selectEmpty}
                    //   renderValue={(selected) => selected.join(", ")}
                    //   MenuProps={MenuProps}
                    // >
                    //   {gmSeg.map((name) =>
                    //     gameSegAbb.map(
                    //       ([key, value]) =>
                    //         name == key && (
                    //           <MenuItem key={name} value={value}>
                    //             <Checkbox
                    //               checked={props.gameSegm.indexOf(value) >= 0}
                    //             />
                    //             <ListItemText primary={value} />
                    //           </MenuItem>
                    //         )
                    //     )
                    //   )}
                    // </Select>

                  )

                  }

                  {'outcomes' in props.filter &&
                    <NativeSelect
                      style={{ marginBottom: "15px" }}
                      value={props.filter.outcomes}
                      onChange={props.filterChange}
                      name="outcomes"
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "ALl Leagues" }}
                    >
                      <option value="">ALL OUTCOMES</option>
                      <option value="WINS">WINS</option>
                      <option value="LOSSES">LOSSES</option>
                      <option value="TIES">TIES</option>
                    </NativeSelect>
                  }
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedI"
                        color="default"
                        inputProps={{
                          "aria-label": "checkbox with default color",
                        }}
                      />
                    }
                    label="Also show prediction"
                  /> */}
                </>
              )}
              <div className="footer">
                <button
                  onClick={(e) => {
                    applyFilter(e);
                  }}
                  className="applyFilter"
                >
                  APPLY
                </button>
              </div>
            </ul>
          </nav>
        </div>
        <HeaderTabs
          onTabChanged={props.onTabChanged}
          header={props.tabHeader}
          navTab={props.navTabNumber}
          arrayFilter={props.appliedFilters}
          deleteFilterChip={props.deleteFilterChip}
        />
      </header>
      <div className="wrapper"></div>
    </>
  );
};

export { Filter };
