import React, { useEffect, useState } from "react";
import arrow from "../../../img/arrow.png";
import arrowG from "../../../img/expendG.png";
import lock from "../../../img/lock.png";
import _ from "lodash";
import { ON_BOARDING } from "../../../constants";

export const BetScroll = ({
    game, pointSpreadBets, betPointSpread, pointSpreadBetsClick, sportBookOverlay,
    gameTotalBets, betGameTotal, gameTotalClick,
    moneyLineBets, betMoneyLine, moneyLineClick,
    firstHalfSpread, bet1HfSpread, firstHalfSpreadClick,
    firstHalfTotalBets, bet1HfTotal, firstHalfTotalClick,
    firstHalfMoneyLine, bet1HfMoneyLine, firstHaltMoneyLineClick,
    gamePropBets, betGameProp, gamePropClick,
    currentLeague,
    userSetting,
    propBet1, propBet2, propBet3,
    history, userPlan, ...props
}) => {
    const [state, setState] = useState(1)
    const [firstHoursBetAvailable, setFirstHoursBetAvailable] = useState(false)
    const [propBetsAvaliable, setPropsBet] = useState(false)
    useEffect(() => {
        if (!_.isEmpty(firstHalfSpread[0]) || !_.isEmpty(firstHalfSpread[1]) || !_.isEmpty(firstHalfTotalBets[0]) || !_.isEmpty(firstHalfTotalBets[1]) || !_.isEmpty(firstHalfMoneyLine[0]) || !_.isEmpty(firstHalfMoneyLine[1])) {
            setFirstHoursBetAvailable(true)
        }
        if (props?.showOnboardingNotifications != ON_BOARDING[2] && (propBet1 || propBet2 || propBet3))
            setPropsBet(true);

    }, []);

    const onBetClick = (e, bets, type, game, index, bet) => {
        e.stopPropagation();
        e.preventDefault();
        if (!_.isEmpty(bets[index])) {
            if (_.isEmpty(bet) || bet?.index != index) {
                if (ON_BOARDING?.includes(props?.showOnboardingNotifications))
                    props?.setShowOnboardingNotifications(ON_BOARDING[4])
                sportBookOverlay(bets, type, game, index, bet)
            }
            else {
                if (type == "POINT SPREAD")
                    pointSpreadBetsClick(bets, index)
                else if (type == "GAME TOTAL")
                    gameTotalClick(bets, index)
                else if (type == "MONEY LINE")
                    moneyLineClick(bets, index)
                else if (type == "1ST HALF SPREAD")
                    firstHalfSpreadClick(bets, index)
                else if (type == "1ST HALF MONEYLINE")
                    firstHaltMoneyLineClick(bets, index)
                else if (type == "1ST HALF TOTAL")
                    firstHalfTotalClick(bets, index)
                else if (type == "GAME PROP")
                    gamePropClick(bets, index)
            }
        }
    }

    const onPropBetClick = (e, game, propBet) => {
        e.preventDefault();
        if (propBet != "") {
            localStorage.setItem("propBetG", game.gameId)
            history.push("/propBets/" + propBet)
        }
    }

    const MMABets = () => {

        return <>
            {state == 1 && <div>
                <div className="headingBet">{currentLeague?.sport == "MMA" ? "KEY BETS FOR FULL BOUT" : "FULL GAME"} </div>
                <div className="df fdr" style={{ justifyContent: "space-between" }}>
                    <img src={arrowG} style={{ transform: "rotate(180deg)", height: "20px", width: "20px", marginTop: "13px" }} />
                    {/* bet opttion 3 */}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betMoneyLine?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, moneyLineBets, "MONEY LINE", game, 0, betMoneyLine)}>
                        {betMoneyLine?.index == 0 ?
                            <> <div >{game?.awayTeam?.mascot?.substring(0, 3)} </div> <div>{betMoneyLine?.moneyline > 0 ? "+" + betMoneyLine?.moneyline : betMoneyLine?.moneyline}</div> </>
                            : !_.isEmpty(moneyLineBets[0]) ?
                                <div > <div >{game?.awayTeam?.mascot?.substring(0, 3)}</div> {moneyLineBets?.[0]?.moneyline > 0 ? "+" + moneyLineBets?.[0]?.moneyline : moneyLineBets?.[0]?.moneyline}</div>
                                : <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion  6*/}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betGameTotal?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, gameTotalBets, "GAME TOTAL", game, 1, betGameTotal)}>
                        {betGameTotal?.index == 1 ? <>
                            <div >O {betGameTotal?.betline}</div>
                            {betGameTotal?.moneyline > 0 ? "+" + betGameTotal?.moneyline : betGameTotal?.moneyline}
                        </> :
                            !_.isEmpty(gameTotalBets[1]) ? <>
                                <div >O {gameTotalBets[1]?.betline}</div>
                                {gameTotalBets[1]?.moneyline > 0 ? "+" + gameTotalBets[1]?.moneyline : gameTotalBets[1]?.moneyline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion  5*/}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betGameTotal?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, gameTotalBets, "GAME TOTAL", game, 0, betGameTotal)}>
                        {betGameTotal?.index == 0 ? <>
                            <div >U {betGameTotal?.betline}</div>
                            {betGameTotal?.moneyline > 0 ? "+" + betGameTotal?.moneyline : betGameTotal?.moneyline}
                        </> :
                            !_.isEmpty(gameTotalBets[0]) ? <>
                                <div >U {gameTotalBets[0]?.betline}</div>
                                {gameTotalBets[0]?.moneyline > 0 ? "+" + gameTotalBets[0]?.moneyline : gameTotalBets[0]?.moneyline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion  100*/}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betGameProp?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, gamePropBets, "GAME PROP", game, 0, betGameProp)}>
                        {betGameProp?.index == 0 ? <>
                            <div >DIST Y</div>
                            {betGameProp?.moneyline > 0 ? "+" + betGameProp?.moneyline : betGameProp?.moneyline}
                        </> :
                            !_.isEmpty(gamePropBets[0]) ? <>
                                <div >DIST Y</div>
                                {gamePropBets[0]?.moneyline > 0 ? "+" + gamePropBets[0]?.moneyline : gamePropBets[0]?.moneyline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>
                    {/* bet opttion  101*/}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betGameProp?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, gamePropBets, "GAME PROP", game, 1, betGameProp)}>
                        {betGameProp?.index == 1 ? <>
                            <div >DIST N</div>
                            {betGameProp?.moneyline > 0 ? "+" + betGameProp?.moneyline : betGameProp?.moneyline}
                        </> :
                            !_.isEmpty(gamePropBets[1]) ? <>
                                <div >DIST N</div>
                                {gamePropBets[1]?.moneyline > 0 ? "+" + gamePropBets[1]?.moneyline : gamePropBets[1]?.moneyline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>
                    {/* bet option 4 */}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betMoneyLine?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, moneyLineBets, "MONEY LINE", game, 1, betMoneyLine)}>
                        {betMoneyLine?.index == 1 ?
                            <> <div >{game?.homeTeam?.mascot?.substring(0, 3)} </div> <div>{betMoneyLine?.moneyline > 0 ? "+" + betMoneyLine?.moneyline : betMoneyLine?.moneyline}</div> </>
                            : !_.isEmpty(moneyLineBets[0]) ?
                                <div > <div >{game?.homeTeam?.mascot?.substring(0, 3)}</div> {moneyLineBets?.[1]?.moneyline > 0 ? "+" + moneyLineBets?.[1]?.moneyline : moneyLineBets?.[1]?.moneyline}</div>
                                : <><div>NOT</div>YET</>}
                    </div>

                    {propBetsAvaliable ? <img src={arrow} style={{ transform: "rotate(180deg)", height: "20px", width: "20px", marginTop: "13px" }} onClick={(e) => { e.preventDefault(e); setState(2) }} /> :
                        <img src={arrowG} style={{ height: "20px", width: "20px", marginTop: "13px" }} />}

                </div>
            </div>}
            {state == 2 && <div>
                {/* <div className="headingBet">{currentLeague?.sport == "Baseball" ? "FIRST 5" : "1ST HALF"}</div> */}
                <div className="headingBet">{"PROP BETS"}</div>

                <div className="df fdr" style={{ justifyContent: "space-between" }}>
                    <img src={arrow} disabled style={{ height: "20px", width: "20px", marginTop: "13px" }} onClick={() => setState(state - 1)} />
                    {(!userPlan?.features?.includes("propBets")) ? <button className="upgradePlan" style={{ width: "85%", maxWidth: "none", padding: "0" }} onClick={() => { history.push("/subscription") }}>
                        <img src={lock} width='21px' style={{ margin: '-3px 5px 0' }} />
                        UPGRADE SUBSCRIPTION</button> :
                        <>
                            {/* bet opttion 7 */}
                            <div className={`newBet df fdc fs-11 fontWhite text-center p-t-12 maxWidth ${propBet1 == "" ? "disabledBet" : ""}`} onClick={(e) => onPropBetClick(e, game, propBet1)}>
                                {propBet1}
                            </div>

                            {/* bet opttion 9 */}
                            <div className={`newBet df fdc fs-11 fontWhite text-center p-t-12 maxWidth ${propBet2 == "" ? "disabledBet" : ""}`} onClick={(e) => onPropBetClick(e, game, propBet2)}>
                                {propBet2}
                            </div>

                            {/* bet opttion  12*/}
                            <div className={`newBet df fdc fs-11 fontWhite text-center p-t-12 maxWidth ${propBet3 == "" ? "disabledBet" : ""}`} onClick={(e) => onPropBetClick(e, game, propBet3)}>
                                {propBet3}
                            </div>
                        </>}

                    <img src={arrowG} style={{ height: "20px", width: "20px", marginTop: "13px" }} />
                </div>
            </div>}
        </>
    }

    const OtherGames = () => {
        return <>
            {state == 1 && <div>
                <div className="headingBet">{currentLeague?.sport == "MMA" ? "KEY BETS FOR FULL BOUT" : "FULL GAME"} </div>
                <div className="df fdr" style={{ justifyContent: "space-between" }}>
                    <img src={arrowG} style={{ transform: "rotate(180deg)", height: "20px", width: "20px", marginTop: "13px" }} />

                    {/* bet opttion 1 */}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betPointSpread?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, pointSpreadBets, "POINT SPREAD", game, 0, betPointSpread)}>
                        {betPointSpread?.index == 0 ? <>
                            {betPointSpread?.betline > 0 ? "+" + betPointSpread?.betline : betPointSpread?.betline}
                            <div >{betPointSpread?.moneyline > 0 ? "+" + betPointSpread?.moneyline : betPointSpread?.moneyline}</div>
                        </> :
                            !_.isEmpty(pointSpreadBets[0]) ? <>
                                {pointSpreadBets?.[0]?.betline > 0 ? "+" + pointSpreadBets?.[0]?.betline : pointSpreadBets?.[0]?.betline}
                                <div >{pointSpreadBets?.[0]?.moneyline > 0 ? "+" + pointSpreadBets?.[0]?.moneyline : pointSpreadBets?.[0]?.moneyline}</div>
                            </> : <><div>NOT</div>YET</>
                        }
                    </div>

                    {/* bet opttion 3 */}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betMoneyLine?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, moneyLineBets, "MONEY LINE", game, 0, betMoneyLine)}>
                        {betMoneyLine?.index == 0 ? <div style={{ marginTop: '7px' }}>{betMoneyLine?.moneyline > 0 ? "+" + betMoneyLine?.moneyline : betMoneyLine?.moneyline}</div> :
                            !_.isEmpty(moneyLineBets[0]) ? <div style={{ marginTop: '7px' }}>{moneyLineBets?.[0]?.moneyline > 0 ? "+" + moneyLineBets?.[0]?.moneyline : moneyLineBets?.[0]?.moneyline}</div> : <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion  6*/}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betGameTotal?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, gameTotalBets, "GAME TOTAL", game, 1, betGameTotal)}>
                        {betGameTotal?.index == 1 ? <>
                            <div >Over </div>
                            {betGameTotal?.betline}
                        </> :
                            !_.isEmpty(gameTotalBets[1]) ? <>
                                <div >Over </div>
                                {gameTotalBets[1]?.betline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>
                    {/* bet opttion  5*/}

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betGameTotal?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, gameTotalBets, "GAME TOTAL", game, 0, betGameTotal)}>
                        {betGameTotal?.index == 0 ? <>
                            <div >Under </div>
                            {betGameTotal?.betline}
                        </> :
                            !_.isEmpty(gameTotalBets[0]) ? <>
                                <div >Under </div>
                                {gameTotalBets[0]?.betline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>

                    {/* bet option 4 */}

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betMoneyLine?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, moneyLineBets, "MONEY LINE", game, 1, betMoneyLine)}>
                        {betMoneyLine?.index == 1 ? <div style={{ marginTop: '7px' }}>{betMoneyLine?.moneyline > 0 ? "+" + betMoneyLine?.moneyline : betMoneyLine?.moneyline}</div> :
                            !_.isEmpty(moneyLineBets[1]) ? <div style={{ marginTop: '7px' }}>{moneyLineBets?.[1]?.moneyline > 0 ? "+" + moneyLineBets?.[1]?.moneyline : moneyLineBets?.[1]?.moneyline}</div> :
                                <><div>NOT</div>YET</>}
                    </div>
                    {/* bet option 2 */}

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${betPointSpread?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, pointSpreadBets, "POINT SPREAD", game, 1, betPointSpread)}>
                        {betPointSpread?.index == 1 ? <>
                            {betPointSpread?.betline > 0 ? "+" + betPointSpread?.betline : betPointSpread?.betline}
                            <div >{betPointSpread?.moneyline > 0 ? "+" + betPointSpread?.moneyline : betPointSpread?.moneyline}</div>
                        </> :
                            !_.isEmpty(pointSpreadBets[1]) ? <>
                                {pointSpreadBets?.[1]?.betline > 0 ? "+" + pointSpreadBets?.[1]?.betline : pointSpreadBets?.[1]?.betline}
                                <div >{pointSpreadBets?.[1]?.moneyline > 0 ? "+" + pointSpreadBets?.[1]?.moneyline : pointSpreadBets?.[1]?.moneyline}</div>
                            </> : <><div>NOT</div>YET</>}
                    </div>

                    {firstHoursBetAvailable ? <img src={arrow} style={{ transform: "rotate(180deg)", height: "20px", width: "20px", marginTop: "13px" }} onClick={(e) => { e.preventDefault(e); setState(2) }} /> :
                        propBetsAvaliable ? <img src={arrow} style={{ transform: "rotate(180deg)", height: "20px", width: "20px", marginTop: "13px" }} onClick={(e) => { e.preventDefault(e); setState(3) }} /> :
                            <img src={arrowG} style={{ height: "20px", width: "20px", marginTop: "13px" }} />}

                </div>
            </div>}
            {/* first half */}
            {state == 2 && <div>
                <div className="headingBet">{currentLeague?.sport == "Baseball" ? "FIRST 5" : "1ST HALF"}</div>
                <div className="df fdr" style={{ justifyContent: "space-between" }}>
                    {/* bet opttion 7 */}
                    <img src={arrow} disabled style={{ height: "20px", width: "20px", marginTop: "13px" }} onClick={() => setState(1)} />

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${bet1HfSpread?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, firstHalfSpread, "1ST HALF SPREAD", game, 0, bet1HfSpread)}>
                        {bet1HfSpread?.index == 0 ? <>
                            {bet1HfSpread?.betline > 0 ? "+" + bet1HfSpread?.betline : bet1HfSpread?.betline}
                            <div >{bet1HfSpread?.moneyline > 0 ? "+" + bet1HfSpread?.moneyline : bet1HfSpread?.moneyline}</div>
                        </> :
                            !_.isEmpty(firstHalfSpread[0]) ? <>
                                {firstHalfSpread?.[0]?.betline > 0 ? "+" + firstHalfSpread?.[0]?.betline : firstHalfSpread?.[0]?.betline}
                                <div >{firstHalfSpread?.[0]?.moneyline > 0 ? "+" + firstHalfSpread?.[0]?.moneyline : firstHalfSpread?.[0]?.moneyline}</div>
                            </> : <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion 9 */}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${bet1HfMoneyLine?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, firstHalfMoneyLine, "1ST HALF MONEYLINE", game, 0, bet1HfMoneyLine)}>
                        {bet1HfMoneyLine?.index == 0 ? <div style={{ marginTop: '7px' }}>{bet1HfMoneyLine?.moneyline > 0 ? "+" + bet1HfMoneyLine?.moneyline : bet1HfMoneyLine?.moneyline}</div> :
                            !_.isEmpty(firstHalfMoneyLine[0]) ? <div style={{ marginTop: '7px' }}>{firstHalfMoneyLine?.[0]?.moneyline > 0 ? "+" + firstHalfMoneyLine?.[0]?.moneyline : firstHalfMoneyLine?.[0]?.moneyline}</div> : <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion  12*/}
                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${bet1HfTotal?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, firstHalfTotalBets, "1ST HALF TOTAL", game, 1, bet1HfTotal)}>
                        {bet1HfTotal?.index == 1 ? <>
                            <div >Over </div>
                            {bet1HfTotal?.betline}
                        </> :
                            !_.isEmpty(firstHalfTotalBets[1]) ? <>
                                <div >Over </div>
                                {firstHalfTotalBets[1]?.betline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>

                    {/* bet opttion  11*/}

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${bet1HfTotal?.index == 0 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, firstHalfTotalBets, "1ST HALF TOTAL", game, 0, bet1HfTotal)}>
                        {bet1HfTotal?.index == 0 ? <>
                            <div >Under </div>
                            {bet1HfTotal?.betline}
                        </> :
                            !_.isEmpty(firstHalfTotalBets[0]) ? <>
                                <div >Under </div>
                                {firstHalfTotalBets[0]?.betline}
                            </>
                                :
                                <><div>NOT</div>YET</>}
                    </div>

                    {/* bet option 10 */}

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${bet1HfMoneyLine?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, firstHalfMoneyLine, "1ST HALF MONEYLINE", game, 1, bet1HfMoneyLine)}>
                        {bet1HfMoneyLine?.index == 1 ?
                            <div style={{ marginTop: '7px' }}>{bet1HfMoneyLine?.moneyline > 0 ? "+" + bet1HfMoneyLine?.moneyline : bet1HfMoneyLine?.moneyline}</div> :
                            !_.isEmpty(firstHalfMoneyLine[1]) ? <div style={{ marginTop: '7px' }}>{firstHalfMoneyLine?.[1]?.moneyline > 0 ? "+" + firstHalfMoneyLine?.[1]?.moneyline : firstHalfMoneyLine?.[1]?.moneyline}</div> :
                                <><div>NOT</div>YET</>}
                    </div>

                    {/* bet option 8 */}

                    <div className={`newBet df fdc fs-11 fontWhite text-center p-t-03 ${bet1HfSpread?.index == 1 ? "activeBet" : ""}`} onClick={(e) => onBetClick(e, firstHalfSpread, "1ST HALF SPREAD", game, 1, bet1HfSpread)}>
                        {bet1HfSpread?.index == 1 ?
                            <>
                                {bet1HfSpread?.betline > 0 ? "+" + bet1HfSpread?.betline : bet1HfSpread?.betline}
                                <div >{bet1HfSpread?.moneyline > 0 ? "+" + bet1HfSpread?.moneyline : bet1HfSpread?.moneyline}</div>
                            </>
                            : !_.isEmpty(firstHalfSpread[1]) ? <>
                                {firstHalfSpread?.[1]?.betline > 0 ? "+" + firstHalfSpread?.[1]?.betline : firstHalfSpread?.[1]?.betline}
                                <div >{firstHalfSpread?.[1]?.moneyline > 0 ? "+" + firstHalfSpread?.[1]?.moneyline : firstHalfSpread?.[1]?.moneyline}</div>
                            </> : <><div>NOT</div>YET</>}
                    </div>
                    {propBetsAvaliable ? <img src={arrow} style={{ transform: "rotate(180deg)", height: "20px", width: "20px", marginTop: "13px" }} onClick={(e) => { e.preventDefault(e); setState(3) }} /> :
                        <img src={arrowG} style={{ height: "20px", width: "20px", marginTop: "13px" }} />}
                </div>
            </div>}
            {state == 3 && <div>
                <div className="headingBet">{"PROP BETS"}</div>

                <div className="df fdr" style={{ justifyContent: "space-between" }}>
                    <img src={arrow} disabled style={{ height: "20px", width: "20px", marginTop: "13px" }} onClick={() => setState(firstHoursBetAvailable ? 2 : 1)} />
                    {(!userPlan?.features?.includes("propBets")) ? <button className="upgradePlan" style={{ width: "85%", maxWidth: "none", padding: "0" }} onClick={() => { history.push("/subscription") }}>
                        <img src={lock} width='21px' style={{ margin: '-3px 5px 0' }} />
                        UPGRADE SUBSCRIPTION</button> :
                        <>
                            {/* bet opttion 7 */}
                            <div className={`newBet df fdc fs-11 fontWhite text-center p-t-12 maxWidth ${propBet1 == "" ? "disabledBet" : ""}`} onClick={(e) => onPropBetClick(e, game, propBet1)}>
                                {propBet1}
                            </div>

                            {/* bet opttion 9 */}
                            <div className={`newBet df fdc fs-11 fontWhite text-center p-t-12 maxWidth ${propBet2 == "" ? "disabledBet" : ""}`} onClick={(e) => onPropBetClick(e, game, propBet2)}>
                                {propBet2}
                            </div>

                            {/* bet opttion  12*/}
                            <div className={`newBet df fdc fs-11 fontWhite text-center p-t-12 maxWidth ${propBet3 == "" ? "disabledBet" : ""}`} onClick={(e) => onPropBetClick(e, game, propBet3)}>
                                {propBet3}
                            </div>
                        </>}
                    <img src={arrowG} style={{ height: "20px", width: "20px", marginTop: "13px" }} />
                </div>
            </div>}
        </>
    }
    return (
        <>
            <div className="container m-t-05 " >
                {/* Full game */}
                {currentLeague?.sport == "MMA" ? MMABets() : OtherGames()}
            </div>
        </>
    );
}






