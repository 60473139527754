import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

const token = "d83ffe28d17a8c843079d4eecf73d8c7fe0dce19";

export const _postContext = async (data = {}) => {
  data ={
    ...data,
    redirectUrl: serverURL.includes("btdev") ? "https://alpha.bettortakes.com/betSync" : "https://m.bettortakes.com/betSync"
  };
  const url = 'https://api.sharpsports.io/v1/context';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json();   
  } catch (error) {
    return {};
  }
 
}


export const _getReconcile = async (userId) => {
  const requestURL = serverURL + "sharpsports/reconcile"
  let params = _({
    userId,
  }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
  var response = await axios.get(requestURL, { params });
  if (response?.data && response?.data?.length)
    return { success: true, data: response.data };
  else
    return { success: false };
}

export const _mergeReconcileBets = async (toIgnore, toReplace) => {
  try {
    const requestURL = serverURL + "sharpsports/reconcile"
    let body = _({
      toIgnore, toReplace
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.put(requestURL, body)

    if (response.data)
      return { success: true, data: response.data };
    else
      return { success: false };
  } catch (error) {
    return { success: false };

  }
}

export const _refreshBets = async (bactid) => {
  const requestURL = `https://api.sharpsports.io/v1/bettorAccounts/${bactid}/refresh`;

  console.log(requestURL);
  try {
    const response =await fetch(requestURL, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${token}`
      },
    });
    if (response.data)
      return { success: true, data: response.data };
    else
      return { success: false };
  } catch (error) {
    return { success: false };

  }
}