import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");


export const _circleNameChanged = async (name, newName) => {
    const requestURL = serverURL + "circles/names"
    let body = {
        name,
        newName,
        "context": "userId"
    }
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response;
}

export const _getMyCircles = async (userId, includePending) => {
    try {
        const requestURL = serverURL + "circles/user";

        let params = _({
            userId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        if (includePending)
            params.includePending = true
        var response = await axios.get(requestURL, { params });
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}
export const _joinCircle = async (userId, screenName, circle, recruiter, recruiterName, role) => {
    try {
        const requestURL = serverURL + "circles";
        let body = _({
            userId, screenName, circle, recruiter, recruiterName, role
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        body.recruiterName = recruiterName || "";
        let response = await axios.post(requestURL, body)
        response = response?.data
        if (response?.newRecord) {
            const check = await _isCircleAvailable(response?.newRecord.circle);
            if (check.success) {
                if (check.data?.length > 1) {
                    await _deleteCircle(response?.newRecord._id)
                }
            }
        }
        return response;

    } catch (error) {
        return { success: false }
    }
}
export const _getMyInvites = async (userId) => {
    try {
        const requestURL = serverURL + "circles/invites"
        let params = _({
            userId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}
export const _isCircleAvailable = async (circle) => {
    try {
        const requestURL = serverURL + "circles"
        let params = _({
            "circle[0]": circle
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        response = response.data;
        if (response.length > 0)
            return { data: response, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}
export const _deleteCircle = async (circleId) => {
    try {
        const requestURL = serverURL + "circles"
        let params = _({
            _id: circleId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.delete(requestURL, { params });
        response = response.data;
        console.log("delete", response);
        return response

    } catch (error) {
        return { success: false }
    }
}

export const _createCircle = async (userId, screenName, circle, recruiter, recruiterName = "") => {
    const requestURL = serverURL + "circles";
    let body = _({
        userId, screenName, circle, recruiter, recruiterName,
        role: "Owner"
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    body.recruiterName = recruiterName || "";
    let response = await axios.post(requestURL, body)
    response = response?.data
    if (response?.newRecord) {
        const check = await _isCircleAvailable(response?.newRecord.circle);
        if (check.success) {
            if (check.data?.length > 1) {
                await _deleteCircle(response?.newRecord._id)
            }
        }
    }
    return response;
}

export const _updateCircle = async (newName, name, context = "circle") => {
    const requestURL = serverURL + "circles/names";
    let body = _({
        name, newName, context
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();

    let response = await axios.put(requestURL, body)
    response = response?.data
    return response;
}

export const _sendInvitation = async (userId, userName, circle, recruiter, recruiterName, screenName, mobilePhone) => {
    try {
        const requestURL = serverURL + "circles"
        let body = {
            userId,
            // userName,
            circle,
            recruiter,
            screenName,
            recruiterName,
        }
        if (mobilePhone)
            body.mobilePhone = mobilePhone;
        // console.log(body);
        let response = await axios.post(requestURL, body)
        response = response?.data
        return { success: true, data: response };
    }
    catch (err) {
        return { success: false, data: null };
    }
}

export const _acceptRequest = async (circle, userId) => {
    const requestURL = serverURL + "circles"
    let body = {
        circle, userId,
        role: "Member"
    }
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response;
}

export const _updateUserRole = async (circle, userId, role) => {
    const requestURL = serverURL + "circles"
    let body = {
        circle,
        userId,
        role
    }
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response;
}

export const _updateAllCircles = async (name, newName) => {
    const requestURL = serverURL + "circles/names"
    let body = {
        name,
        newName,
        context: "screenName"
    }
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response;
}

export const _kickUser = async (_id) => {
    const requestURL = serverURL + "circles?_id=" + _id;
    let response = await axios.delete(requestURL)
    response = response?.data
    return response;
}

export const _leaveCircle = async (circle, userId) => {
    
    const requestURL = serverURL + `circles?circle=${circle}&userId=${userId}`;
    let response = await axios.delete(requestURL)
    response = response?.data
    return response;
}

export const _getCircleContest = async (circle, user) => {
    try {
        const requestURL = serverURL + "contests"
        let params = _({
            circle,
            user
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        let response = await axios.get(requestURL, { params })
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }
    } catch (error) {
        return { success: false }
    }
}

export const _joinContest = async (contestId, userId) => {
    try {
        
        const requestURL = serverURL + "contests/join"
        let body = {
            contestId, userId
        }
        var response = await axios.put(requestURL, body);
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }
    } catch (error) {
        return { success: false }
    }
}

export const _getContestLeaderboard = async (contestId) => {
    try {
        const requestURL = serverURL + "contests/leaderboard"
        let params = _({
            contestId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        let response = await axios.get(requestURL, { params })
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }
    } catch (error) {
        return { success: false }
    }
}

export const _getCircleName = async (_id) => {
    try {
        const requestURL = serverURL + "circles/invite"
        let params = _({
            _id
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        let response = await axios.get(requestURL, { params })
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }
    } catch (error) {
        return { success: false }
    }
}

export const _getCircleInfo = async (circle) => {
    try {
        const requestURL = serverURL + "circles/info"
        let params = _({
            circle
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        let response = await axios.get(requestURL, { params })
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }
    } catch (error) {
        return { success: false }
    }
}